import { Localization } from 'app/share/data-type/common.types';

export const ServicesWarehousingDistributionLocalization: {
  [key: string]: ServicesWarehousingDistributionLocalizationClass;
} = {
  en: {
    subTitle: 'Logistics',
    title: 'Comprehensive Warehousing Services',
    nlWareHouse: 'Looking for Warehousing Services in Europe?',
    nlWareHouseLink: 'Click Here',
    description1: `ClearFreight's warehousing services are designed to optimize your supply chain by providing secure and efficient storage solutions. Our state-of-the-art warehouses are strategically located to ensure proximity to major transportation hubs, facilitating seamless distribution. From inventory management to order fulfillment, ClearFreight offers a comprehensive suite of warehousing solutions to meet the dynamic needs of your business.`,
    description2: `Choose ClearFreight for scalable and flexible warehousing options tailored to your specific requirements. Our advanced warehouse management systems enable real-time visibility into your inventory, ensuring accuracy and transparency. Benefit from our experienced team's expertise in handling a diverse range of products, and let ClearFreight be your trusted partner in optimizing storage and distribution.`,
    services: 'Our comprehensive warehousing services include:',
    servicesList: [
      'Bonded and non-bonded warehousing',
      'Order Fulfillment',
      'Kitting',
      'Labeling',
      'E-commerce fulfillment',
      'Purchase Order Management',
      'Cross Docking',
      'Pick and Pack Services',
      'Reverse Logistics',
      'Cargo Consolidations',
      'Storage',
      'Inventory Management',
      'Export Ocean Container Loading',
      'EDI Capabilities',
      '24-hour drop off facility with onsite security',
      'Yard storage for multiple containers',
      'Dedicated chassis to service LA/LGB & Oakland in CA; Houston in TX; and Savannah in GA',
      'Intermodal Solutions for Safer and More Cost-Effective Shipping',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};
export interface ServicesWarehousingDistributionLocalizationClass
  extends Localization {
  nlWareHouse?: string;
  nlWareHouseLink?: string;
}
