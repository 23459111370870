export const location_data = [
  {
    areaName: 'AMERICAS',
    center: { lat: 33.6016101, lng: -84.3898267 },
    children: [
      {
        id: '1',
        name: 'Atlanta',
        country: 'US',
        countryFull: 'United States',
        address: '114 Southfield Pkwy Suite 120 Forest Park, GA 30297',
        phone: '404-669-9155',
        email: 'atlanta@clearfreight.com',
        latlng: { lat: 33.6016101, lng: -84.3898267 },
      },
      {
        id: '2',
        name: 'Chicago',
        country: 'US',
        countryFull: 'United States',
        address: '712 N. Central Avenue, Suite A, Wood Dale, IL 60191',
        phone: '630-766-6652',
        email: 'chicago@clearfreight.com',
        latlng: { lat: 41.9760005, lng: -87.9734974 },
      },
      {
        id: '3',
        name: 'Dallas',
        country: 'US',
        countryFull: 'United States',
        address: '751 Port America Place, Suite 300, Grapevine, TX 76051',
        phone: '817-421-2849',
        email: 'dallas@clearfreight.com',
        latlng: { lat: 32.919879, lng: -97.070786 },
      },
      {
        id: '4',
        name: 'Houston',
        country: 'US',
        countryFull: 'United States',
        address: '2700 Greens Rd, Suite F-300, Houston, TX 77032',
        phone: '281-821-2011',
        email: 'houston@clearfreight.com',
        latlng: { lat: 29.9507766, lng: -95.348518 },
      },
      {
        id: '5',
        name: 'Los Angeles',
        country: 'US',
        countryFull: 'United States',
        address: '1960 E Grand Ave., Suite 700, El Segundo, CA 90245',
        phone: '310-726-0400',
        email: '',
        latlng: { lat: 33.9186242, lng: -118.3950848 },
      },
      {
        id: '6',
        name: 'Miami',
        country: 'US',
        countryFull: 'United States',
        address: '4220 W 104th St., Suite 1, Hialeah, FL 33018',
        phone: '305-592-4446',
        email: 'miami@clearfreight.com',
        latlng: { lat: 25.7952982, lng: -80.382059 },
      },
      {
        id: '7',
        name: 'New York',
        country: 'US',
        countryFull: 'United States',
        address:
          'Building 75, North Hangar Rd. Suite 210, JFK International Airport, Jamaica, NY 11430',
        phone: '718-656-4510',
        email: 'newyork@clearfreight.com',
        latlng: { lat: 40.659682, lng: -73.7827546 },
      },
      {
        id: '8',
        name: 'Salt Lake City',
        country: 'US',
        countryFull: 'United States',
        address: '111 East 5600 South #312, Murray UT 84107',
        phone: '801-523-0505',
        email: 'saltlake@clearfreight.com',
        latlng: { lat: 40.6499374, lng: -111.8894907 },
      },
      {
        id: '9',
        name: 'San Francisco',
        country: 'US',
        countryFull: 'United States',
        address: '1067 Sneath Lane, San Bruno, CA 94066',
        phone: '650-871-9580',
        email: 'sanfrancisco@clearfreight.com',
        latlng: { lat: 37.6335406, lng: -122.4296874 },
      },
      {
        id: '10',
        name: 'Seattle',
        country: 'US',
        countryFull: 'United States',
        address: '14900 Interurban Ave S, Suite 110, Seattle, WA 98168',
        phone: '253-661-9900',
        email: 'seattle@clearfreight.com',
        latlng: { lat: 47.4690529, lng: -122.2551757 },
      },
      {
        id: '11',
        name: 'Santiago',
        country: 'CL',
        countryFull: 'Chile',
        address:
          'El Trovador 4280, office 502, Las Condes, Santiago 7550075, Chile',
        phone: '56-2-3323-1330',
        email: 'CFChile@clearfreight.com',
        latlng: { lat: -33.4134349, lng: -70.5868224 },
      },
      {
        id: '12',
        name: 'Toronto',
        country: 'CA',
        countryFull: 'Canada',
        address:
          '5409 Eglinton Ave West, Suite 203, Etobicoke, Ontario M9C 5K6, Canada',
        phone: '905-671-8088',
        email: 'toronto@clearfreight.com',
        latlng: { lat: 43.661715, lng: -79.590016 },
      },
    ],
  },
  {
    areaName: 'EUROPE',

    center: { lat: 49.266766087823235, lng: 14.254472881431667 },
    children: [
      {
        id: '13',
        name: 'Amsterdam',
        country: 'NL',
        countryFull: 'Netherlands, Holland',
        address:
          'Van IJsendijkstraat 405-B, 1442 LB Purmerend, The Netherlands',
        phone: '31-88-429-1900',
        email: 'amsterdam@clearfreight.nl',
        latlng: { lat: 52.5172466, lng: 4.979362 },
      },
      {
        id: '14',
        name: 'Rotterdam',
        country: 'NL',
        countryFull: 'Netherlands, Holland',
        address: 'Nijverheidsweg 62, 3341 LJ H.I. Ambacht, Rotterdam',
        phone: '31-88-429-1930',
        email: 'rotterdam@clearfreight.nl',
        latlng: { lat: 51.8462568, lng: 4.6509618 },
      },
    ],
  },
  {
    areaName: 'ASIA',
    center: { lat: 31.2508814, lng: 121.4562377 },
    children: [
      {
        id: '15',
        name: 'Beijing',
        country: 'CN',
        countryFull: 'China',
        address:
          'Room 1315, Building 3, No. 106 Kexing West Road, Changping District, Beijing 102208, China',
        phone: '86-10-8173-1622',
        email: 'beijing@clearfreight.com',
        latlng: { lat: 40.0810746, lng: 116.3560664 },
      },
      {
        id: '16',
        name: 'Dalian',
        country: 'CN',
        countryFull: 'China',
        address:
          'No. 68 Ren Min Road, Room 708, Gold Name Tower, Zhong Sha District, Dalian, Liaoning Province, P.R. China 116001',
        phone: '86-411-6288-6299',
        email: 'dalian@clearfreight.com',
        latlng: { lat: 38.9182677, lng: 121.6272115 },
      },
      {
        id: '17',
        name: 'Guangzhou',
        country: 'CN',
        countryFull: 'China',
        address:
          'Room 1209, Universal Plaza, 829 Renmin Road (N), Guangzhou, PRC 510170, China',
        phone: '86-20-8734-0318',
        email: 'guangzhou@clearfreight.com',
        latlng: { lat: 23.1292911, lng: 113.2521141 },
      },
      {
        id: '18',
        name: 'Qingdao',
        country: 'CN',
        countryFull: 'China',
        address:
          '52 Hong Kong Rd. Central, Room 2116 Times Square, Qingdao 266071, China',
        phone: '86-532-8667-5390',
        email: 'qingdao@clearfreight.com',
        latlng: { lat: 36.058025, lng: 120.3684263 },
      },
      {
        id: '19',
        name: 'Shanghai',
        country: 'CN',
        countryFull: 'China',
        address:
          '547 Tianmu Road West, Room 1608, Building C, Unicom International Tower, Shanghai 200070, China',
        phone: '86-21-6317-1616',
        email: 'shanghai@clearfreight.com',
        latlng: { lat: 31.2508814, lng: 121.4562377 },
      },
      {
        id: '20',
        name: 'Shenzhen',
        countryFull: 'China',
        country: 'CN',
        address:
          'Room 2503, CunJin Building, 3005 DongMen South Road, LuoHu District, Shenzhen 518001, China',
        phone: '86-755-2559-3887',
        email: 'shenzen@clearfreight.com',
        latlng: { lat: 22.5416961, lng: 114.1193672 },
      },
      {
        id: '21',
        name: 'Xiamen',
        country: 'CN',
        countryFull: 'China',
        address:
          'Room 1103B, 357 - 359 Hubin South Road Hai Sheng International Building Xiamen 361004, China',
        phone: '86-592-227-8608',
        email: 'xiamen@clearfreight.com',
        latlng: { lat: 24.4662997, lng: 118.1030998 },
      },
      {
        id: '22',
        name: 'Hong Kong',
        country: 'HK',
        countryFull: 'Hong Kong',
        address:
          'Units 1913-16, 19/F, Tower A, Regent Centre, 63 Wo Yi Hop Road, Kwai Chung, N.T., Hong Kong',
        phone: '852-2619-0603',
        email: 'hongkong@clearfreight.com',
        latlng: { lat: 22.3672896, lng: 114.1361184 },
      },
      {
        id: '23',
        name: 'Taipei',
        country: 'TW',
        countryFull: 'Taiwan',
        address: 'Rm.815, 8F, No. 152, Songjiang Road, Taipei 10458, Taiwan',
        phone: '886-2-2766-0758',
        email: 'taipei@clearfreight.com',
        latlng: { lat: 25.0541901, lng: 121.5328497 },
      },
      {
        id: '24',
        name: 'Tokyo',
        country: 'JP',
        countryFull: 'Japan',
        address:
          'Sankyo Seaside Campus, 3-17-6 Higashi-Shinagawa, Shinagawa-ku, Tokyo 140-0002, Japan',
        phone: '81-3-6453-6736',
        email: 'tokyo@clearfreight.co.jp',
        latlng: { lat: 35.645992, lng: 139.746881 },
      },
      {
        id: '25',
        name: 'Bangkok',
        country: 'TH',
        countryFull: 'Thailand',
        address:
          '127/14 Panjathani Tower 12th Fl, Nonsri Road, Chongnonsri, Yannawa, Bangkok, 10120 Thailand',
        phone: '66-2-296-1399',
        email: 'bangkok@clearfreight.co.th',
        latlng: { lat: 13.695854, lng: 100.5376116 },
      },
    ],
  },
];
