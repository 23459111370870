import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { ServicesComponent } from './templates/services/services.component';
import { ServicesAirComponent } from './services/air/air.component';
import { ServicesOceanComponent } from './services/ocean/ocean.component';
import { ServicesRailComponent } from './services/rail/rail.component';
import { ServicesRoadComponent } from './services/road/road.component';
import { IndustryComponent } from './templates/industry/industry.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { QuoteRequestComponent } from './quote-request/quote-request.component';
import { DemoScheduleComponent } from './demo-schedule/demo-schedule.component';

import { ServicesBreakBulkProjectCargoComponent } from './services/break-bulk-project-cargo/break-bulk-project-cargo.component';
import { ServicesCargoInsuranceComponent } from './services/cargo-insurance/cargo-insurance.component';
import { ServicesChemicalBulkLiquidComponent } from './services/chemical-bulk-liquid/chemical-bulk-liquid.component';
import { ServicesCustomsHouseBrokerageComponent } from './services/customs-house-brokerage/customs-house-brokerage.component';
import { ServicesDutyDrawbackComponent } from './services/duty-drawback/duty-drawback.component';
import { ServicesFreightForwardingComponent } from './services/freight-forwarding/freight-forwarding.component';
import { ServicesIntermodalSolutionsComponent } from './services/intermodal-solutions/intermodal-solutions.component';
import { ServicesSupplyChainSolutionsComponent } from './services/supply-chain-solutions/supply-chain-solutions.component';
import { ServicesWarehousingDistributionComponent } from './services/warehousing-distribution/warehousing-distribution.component';

import { ContactUSComponent } from './contact-us/contact-us.component';
import { LocationComponent } from './locaition/locaition.component';
import { TechnologyComponent } from './technology/technology.component';
// TODO comment out sandbox component
import { SandboxComponent } from './sandbox/sandbox.component';
import { SearchComponent } from './search/search.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { TrackingComponent } from './tracking/tracking.component';
import { IndustryAutomotiveComponent } from './industry/industry-automotive/industry-automotive.component';
import { IndustryChemicalsComponent } from './industry/industry-chemicals/industry-chemicals.component';
import { IndustryOilGasComponent } from './industry/industry-oil-gas/industry-oil-gas.component';
import { IndustryGovernmentComponent } from './industry/industry-government/industry-government.component';
import { IndustryHealthcareComponent } from './industry/industry-healthcare/industry-healthcare.component';
import { IndustryRetailComponent } from './industry/industry-retail/industry-retail.component';
import { IndustryConsumerGoodsComponent } from './industry/industry-consumer-goods/industry-consumer-goods.component';
import { IndustryCosmeticsComponent } from './industry/industry-cosmetics/industry-cosmetics.component';
import { IndustryElectronicsComponent } from './industry/industry-electronics/industry-electronics.component';
import { IndustryFashionComponent } from './industry/industry-fashion/industry-fashion.component';
import { IndustryPetsComponent } from './industry/industry-pets/industry-pets.component';
import { IndustryFoodBevComponent } from './industry/industry-food-bev/industry-food-bev.component';
import { IndustryJpFoodComponent } from './industry/industry-jp-food/industry-jp-food.component';
import { IndustryDairyComponent } from './industry/industry-dairy/industry-dairy.component';
import { TechnologyOrderManagementComponent } from './technology/order-management/order-management.component';
import { TechnologyMobileAppComponent } from './technology/mobile-app/mobile-app.component';
import { TechnologyAnalyticsComponent } from './technology/analytics/analytics.component';
import { TechnologyCustomIntegrationsComponent } from './technology/custom-integrations/custom-integrations.component';
import { ServicesComplianceConsultingComponent } from './services/compliance-consulting/compliance-consulting.component';
import { NlFormSubmittedComponent } from './nl-form-submitted/nl-form-submitted.component';
import { NlWarehouseComponent } from './nl-warehouse/nl-warehouse.component';
import { FormsComponent } from './resources/forms/forms.component';
import { HarbourAcquisitionComponent } from './harbour-acquisition/harbour-acquisition.component';

const cacheSec = 5;
const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
        data: { revalidate: cacheSec },
      },
      {
        path: 'about',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
        data: { revalidate: cacheSec },
      },
      {
        path: 'demos',
        loadChildren: () =>
          import('./demos/demos.module').then((m) => m.DemoModule),
        data: { revalidate: cacheSec },
      },
      {
        path: 'location',
        data: { title: 'Location | ClearFreight', revalidate: cacheSec },
        children: [
          {
            path: '',
            data: { title: 'Location | ClearFreight', revalidate: cacheSec },
            component: LocationComponent,
          },
          {
            path: ':id',
            data: { title: 'Location | ClearFreight', revalidate: cacheSec },
            component: LocationComponent,
          },
        ],
      },

      {
        path: 'services',
        data: { title: 'Services | ClearFreight', revalidate: cacheSec },
        children: [
          {
            path: '',
            data: { title: 'Services | ClearFreight', revalidate: cacheSec },
            component: ServicesComponent,
          },
          {
            path: 'air',
            data: {
              title: 'Services-Air | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesAirComponent,
          },
          {
            path: 'ocean',
            data: {
              title: 'Services-Ocean | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesOceanComponent,
          },
          {
            path: 'rail',
            data: {
              title: 'Services-Rail | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesRailComponent,
          },
          {
            path: 'road',
            data: {
              title: 'Services-Road | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesRoadComponent,
          },

          {
            path: 'break-bulk-project-cargo',
            data: {
              title: 'Break Bulk Project Cargo | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesBreakBulkProjectCargoComponent,
          },
          {
            path: 'cargo-insurance',
            data: {
              title: 'Cargo Insurance | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesCargoInsuranceComponent,
          },
          {
            path: 'chemical-bulk-liquid',
            data: {
              title: 'Chemical Bulk Liquid | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesChemicalBulkLiquidComponent,
          },
          {
            path: 'customs-house-brokerage',
            data: {
              title: 'Customs House Brokerage | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesCustomsHouseBrokerageComponent,
          },
          {
            path: 'compliance-consulting',
            data: {
              title: 'Compliance & Consulting | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesComplianceConsultingComponent,
          },
          {
            path: 'freight-forwarding',
            data: {
              title: 'Freight Forwarding | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesFreightForwardingComponent,
          },
          {
            path: 'intermodal-solutions',
            data: {
              title: 'Intermodal Solutions | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesIntermodalSolutionsComponent,
          },
          {
            path: 'supply-chain-solutions',
            data: {
              title: 'Supply Chain Solutions | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesSupplyChainSolutionsComponent,
          },
          {
            path: 'warehousing-distribution',
            data: {
              title: 'Warehousing Distribution | ClearFreight',
              revalidate: cacheSec,
            },
            component: ServicesWarehousingDistributionComponent,
          },
        ],
      },
      {
        path: 'industry',
        data: { title: 'Industry | ClearFreight', revalidate: cacheSec },
        children: [
          {
            path: '',
            data: { title: 'Industry | ClearFreight', revalidate: cacheSec },
            component: IndustryComponent,
          },
          {
            path: 'automotive',
            data: { title: 'Automotive | ClearFreight', revalidate: cacheSec },
            component: IndustryAutomotiveComponent,
          },
          {
            path: 'chemicals',
            data: { title: 'Chemicals | ClearFreight', revalidate: cacheSec },
            component: IndustryChemicalsComponent,
          },
          {
            path: 'oil-gas',
            data: { title: 'Oil & Gas | ClearFreight', revalidate: cacheSec },
            component: IndustryOilGasComponent,
          },
          {
            path: 'government',
            data: { title: 'Government | ClearFreight', revalidate: cacheSec },
            component: IndustryGovernmentComponent,
          },
          {
            path: 'healthcare',
            data: { title: 'Healthcare | ClearFreight', revalidate: cacheSec },
            component: IndustryHealthcareComponent,
          },
          {
            path: 'retail',
            data: { title: 'Retail | ClearFreight', revalidate: cacheSec },
            component: IndustryRetailComponent,
          },
          {
            path: 'consumer-goods',
            data: {
              title: 'Consumer Goods | ClearFreight',
              revalidate: cacheSec,
            },
            component: IndustryConsumerGoodsComponent,
          },
          {
            path: 'skin-care-cosmetics',
            data: {
              title: 'Cosmetics | ClearFreight',
              revalidate: cacheSec,
            },
            component: IndustryCosmeticsComponent,
          },
          {
            path: 'electronics',
            data: { title: 'Electronics | ClearFreight', revalidate: cacheSec },
            component: IndustryElectronicsComponent,
          },
          {
            path: 'fashion-apparel',
            data: {
              title: 'Fashion & Apparel | ClearFreight',
              revalidate: cacheSec,
            },
            component: IndustryFashionComponent,
          },
          {
            path: 'pet-products',
            data: {
              title: 'Pet Products | ClearFreight',
              revalidate: cacheSec,
            },
            component: IndustryPetsComponent,
          },
          {
            path: 'food-beverage',
            data: {
              title: 'Food & Beverage | ClearFreight',
              revalidate: cacheSec,
            },
            component: IndustryFoodBevComponent,
          },
          {
            path: 'food-beverage/japanese-food',
            data: {
              title: 'Japanese Food | ClearFreight',
              revalidate: cacheSec,
            },
            component: IndustryJpFoodComponent,
          },
          {
            path: 'food-beverage/dairy',
            data: { title: 'Dairy | ClearFreight', revalidate: cacheSec },
            component: IndustryDairyComponent,
          },
        ],
      },
      {
        path: 'quote',
        data: { title: 'Request a Quote | ClearFreight', revalidate: cacheSec },
        component: QuoteRequestComponent,
      },
      {
        path: 'demo',
        data: { title: 'Schedule a Demo | ClearFreight', revalidate: cacheSec },
        component: DemoScheduleComponent,
      },
      {
        path: 'contact',
        data: { title: 'Contact US | ClearFreight', revalidate: cacheSec },
        component: ContactUSComponent,
      },
      {
        path: 'resources',
        data: { title: 'Resources | ClearFreight', revalidate: cacheSec },
        loadChildren: () =>
          import('./resources/resources.module').then((m) => m.ResourcesModule),
      },
      {
        path: 'forms',
        data: { title: 'Forms | ClearFreight', revalidate: cacheSec },
        component: FormsComponent,
      },
      {
        path: 'contact',
        data: { revalidate: cacheSec },
        loadChildren: () =>
          import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'privacy-policy',
        data: { title: 'Privacy Policy | ClearFreight', revalidate: cacheSec },
        component: PrivacyPolicyComponent,
      },
      {
        path: 'technology',
        data: { title: 'Technology | ClearFreight', revalidate: cacheSec },
        children: [
          {
            path: '',
            data: { title: 'ClearView | ClearFreight', revalidate: cacheSec },
            component: TechnologyComponent,
          },
          {
            path: 'clearview',
            data: { title: 'ClearView | ClearFreight', revalidate: cacheSec },
            component: TechnologyComponent,
          },
          {
            path: 'order-management',
            data: {
              title: 'Order Management | ClearFreight',
              revalidate: cacheSec,
            },
            component: TechnologyOrderManagementComponent,
          },
          {
            path: 'mobile-app',
            data: { title: 'Mobile App | ClearFreight', revalidate: cacheSec },
            component: TechnologyMobileAppComponent,
          },
          {
            path: 'analytics',
            data: { title: 'Analytics | ClearFreight', revalidate: cacheSec },
            component: TechnologyAnalyticsComponent,
          },
          {
            path: 'custom-integrations',
            data: { title: 'Integration | ClearFreight', revalidate: cacheSec },
            component: TechnologyCustomIntegrationsComponent,
          },
        ],
      },
      {
        path: 'search',
        data: { title: 'Search | ClearFreight', revalidate: cacheSec },
        component: SearchComponent,
      },
      {
        path: 'search/:id',
        data: { title: 'Search | ClearFreight', revalidate: cacheSec },
        component: SearchComponent,
      },
      {
        path: 'glossary',
        data: { title: 'Glossary | ClearFreight', revalidate: cacheSec },
        component: GlossaryComponent,
      },
      {
        path: 'tracking',
        data: { title: 'Tracking | ClearFreight', revalidate: cacheSec },
        component: TrackingComponent,
      },
      {
        path: 'tracking/shipment/:id',
        data: { title: 'Tracking | ClearFreight', revalidate: cacheSec },
        component: TrackingComponent,
      },
      {
        path: 'tracking/container/:id',
        data: { title: 'Tracking | ClearFreight', revalidate: cacheSec },
        component: TrackingComponent,
      },
      {
        path: 'nl_form_submitted',
        data: { title: 'Form Submitted | ClearFreight', revalidate: cacheSec },
        component: NlFormSubmittedComponent,
      },
      {
        path: 'nl_warehouse',
        data: { title: 'Warehouse | ClearFreight', revalidate: cacheSec },
        component: NlWarehouseComponent,
      },
      {
        path: 'harbour-acquisition-announcement',
        data: {
          title: 'Harbour Acquisition | ClearFreight',
          revalidate: cacheSec,
        },
        component: HarbourAcquisitionComponent,
      },
      // TODO: comment out later
      // {
      //   path: 'sandbox',
      //   data: { title: 'Sandbox | ClearFreight', revalidate: cacheSec },
      //   component: SandboxComponent,
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
