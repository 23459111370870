<div class="acquisition-announcement">
  <app-center-pic
    src="./assets/images/Template%201.0/CF-1.0-Header.jpg"
  ></app-center-pic>
  <div class="content">
    <img
      class="announcement-document"
      src="./assets/images/ClearFreight-Harbour-Acquisition-Announcement.jpg"
      alt="announcement document"
      draggable="false"
    />
  </div>
</div>
