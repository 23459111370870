import {
  Component,
  OnChanges,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
import { SwiperOptions } from 'swiper';
import { ScreenService } from 'app/services/screen.service';
import { register } from 'swiper/element/bundle';
import { Swiper } from 'swiper/types';

// to=do: remove later
import { environment } from 'environments/environment';
const isDev = environment.cfApiUrl == 'https://dev-site.clearfreight.com/api/';

@Component({
  selector: 'app-newsroom-menu',
  templateUrl: './newsroom-menu.component.html',
  styleUrls: ['./newsroom-menu.component.scss'],
})
export class NewsRoomMenuComponent implements OnChanges, AfterViewInit {
  @Input() current: string = 'Articles';
  @Output() setState = new EventEmitter();
  public isMobile: boolean = false;
  public isDev = isDev; // to=do: remove later

  public slidesPerView: number;

  config: SwiperOptions = {
    pagination: false,
    navigation: false,
    centeredSlides: false,

    autoplay: false,
    loop: true,
    slidesPerView: 2.8,
    speed: 1000,
    spaceBetween: 0,
    parallax: false,
  };
  items = isDev
    ? [
        {
          //   title: "VIDEOS",
          //   link: "/resources/videos",
          //   isCurrent: false
          // }
          // , {
          title: 'Articles',
          link: '/resources/newsroom',
          isCurrent: false,
        },
        {
          title: 'Port Stats',
          link: '/resources/portstats',
          isCurrent: false,
        },
        {
          title: 'Industry News',
          link: '/resources/industry',
          isCurrent: false,
        },
        {
          title: 'Brokerage Updates',
          link: '/resources/brokerage',
          isCurrent: false,
        },
        // {
        //   title: 'WHITEPAPERS',
        //   link: '/resources/whitepapers',
        //   isCurrent: false,
        // },
        //{
        //   title: "PRESS RELEASES",
        //   link: "/resources/press-releases",
        //   isCurrent: false
        // }
        // , {
        //   title: "PRESS",
        //   link: "/resources/press",
        //   isCurrent: false
        // }
        // , {
        // title: 'MARKET UPDATES',
        // link: '/resources/market',
        // isCurrent: false,
        //},
      ]
    : [
        {
          title: 'Articles',
          link: '/resources/newsroom',
          isCurrent: false,
        },
        {
          title: 'Industry News',
          link: '/resources/industry',
          isCurrent: false,
        },
      ];

  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  constructor(private screenService: ScreenService) {
    this.isMobile = this.screenService.isMobile();
    if (this.isMobile) {
      this.config.slidesPerView = 2;
    } else {
      this.config.slidesPerView = 4;
    }
    this.slidesPerView = isDev ? 4 : 2;
  }
  ngAfterViewInit(): void {
    register();
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes[`current`]) {
      let currentIndex = 0;
      this.items.forEach((item, index) => {
        item.isCurrent = false;
        if (item.title == this.current) {
          item.isCurrent = true;
          currentIndex = index;
        }
      });
      this.config.initialSlide = currentIndex;
      if (this.isMobile) {
        this.items = this.moveElementsToStart(this.items, currentIndex);
      }
    }
  }

  moveElementsToStart(arr: any, index: number) {
    if (index < 0 || index >= arr.length) {
      console.error('Index out of bounds');
      return arr;
    }

    const firstPart = arr.splice(index);
    const secondPart = arr;
    return firstPart.concat(secondPart);
  }

  changeSlide(dir: string) {
    if (dir == 'forward') {
      this.swiper?.slideNext();
    } else {
      this.swiper?.slidePrev();
    }
  }
}
