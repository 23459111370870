import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { filter } from 'lodash';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  leadInfoAllowedRoutes: string[] = ['nl_warehouse', 'nl_form_submitted'];
  leadInfoScript = document.createElement('script');

  isLeadInfoAllowedRoute(route: string): boolean {
    return this.leadInfoAllowedRoutes.includes(route);
  }

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentPath = event.urlAfterRedirects.split('/')[1];
        console.log('currentPath', currentPath);

        if (
          this.isLeadInfoAllowedRoute(window.location.pathname.split('/')[1])
        ) {
          this.leadInfoScript.innerHTML = `
            <!-- Leadinfo tracking code -->
            (function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
            l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
            l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
            }(window,document,"script","https://cdn.leadinfo.net/ping.js ","leadinfo","LI-67AA0285179BF"));
          `;
          // append the script to the body
          document.body.appendChild(this.leadInfoScript);
        } else {
          document.body.removeChild(this.leadInfoScript);
        }
      }
    });
  }
}
